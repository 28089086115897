@import "../../../styles/variables";

.container {
  height: 100vh;
  width: 100%;
  background: rgb(248, 249, 250);
  overflow-y: scroll;
  // background-color: #7304e2;
  background-image: url('../../../components/assets/loginBackground2.jpg');
  background-attachment: fixed;
  background-size: cover;
}

.logo {
  > * {
    max-width: 100px;
    display: block;
    margin: 12px auto;
  }
}
